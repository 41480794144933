import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { ReserveNow } from "../hooks/useLinks"
import useViewport from "../hooks/useViewport"
import device, { sizes } from "../device"
import GreenHeading from "../heading"
import Cars from "./cars"
import Slider from "./locations-slider"
import BackgroundImage from 'gatsby-background-image'



const Wrapper = styled.section`
  margin-top: -4%;
  color: var(--white);
  display: grid;
  grid-template-columns: 1;
  grid-template-rows: 1;
  position: relative;
  max-height: 950px;
  ${device.small`min-height: 800px;`}
  .bg {
    grid-row: 1 / -1;
    grid-column: 1 / -1;
    position: relative;
    z-index: -3;
    width: 100%;
    ${device.medium`
    height: 100%;
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }
    `}
  }
  .content-container {
    padding-top: 10%;
    grid-row: 1 /-1;
    grid-column: 1 / -1;
    ${device.small`padding-top: 5rem;`}
  }
  .heading {
    text-align: center;
    margin: 0 auto;
    font-size: 2.5rem;
    line-height: 1;
    ${device.small`font-size: 2rem;`}
  }
  p {
    max-width: 800px;
    font-size: 0.8rem;
    width: 50%;
    margin: 2rem auto;
    text-align: center;
    ${device.small`width: var(--spread);`}
  }
  .locations {
    max-width: 1500px;
    width: 70%;
    margin: 0 auto;
    ${device.small`width: var(--spread);`}
  }
  .button-wrapper {
    padding: 5rem 0;
    text-align: center;
    font-size: 0.7rem;
  }
`

const Categories = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  ${device.small`justify-content: center;`}
  .type {
    color: var(--green);
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 0.9rem;
    padding: 2px 8px;
    cursor: pointer;
    ${device.small`margin: 0.5rem 1rem; padding: 2px 0;`}
    &.selected {
      border-bottom: 3px solid var(--green);
      ${device.small`border-bottom: 2px solid var(--green);`}
    }
  }
`

const ListWrapper = styled.div`
  margin: 3rem 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.5rem 0;
  ${device.small`grid-template-columns: repeat(1, 1fr); margin: 2rem 0; justify-items: center;`}
  span {
    display: flex;
    align-items: center;
    font-size: 0.7rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    &::before {
      margin-right: 0.5rem;
      display: inline-block;
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      opacity: 0.5;
      border: 1px solid var(--white);
    }
  }
`

const List = ({ posts }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [posts])
  return (
    <ListWrapper>
      {loaded &&
        posts.map((item, index) => <span key={index}>{item.title}</span>)}
    </ListWrapper>
  )
}

const Locations = ({ text }) => {
  const isMobile = useViewport() <= sizes.small

  const data = useStaticQuery(graphql`
    {
      allWordpressWpLocationsCategories {
        edges {
          node {
            name
            locations {
              title
            }
          }
        }
      }
      desktopBg: file(relativePath: { eq: "locations/bg-desktop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobileBg: file(relativePath: { eq: "locations/bg-desktop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      firstCar: file(relativePath: { eq: "locations/car1.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      secondCar: file(relativePath: { eq: "locations/car2.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      thirdCar: file(relativePath: { eq: "locations/car3.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  const sources = [
    data.mobileBg.childImageSharp.fluid,
    {
      ...data.desktopBg.childImageSharp.fluid,
      media: `(min-width: ${sizes.small}px)`,
    },
  ]
  const [selected, setSelected] = useState(0)

  const locations = data.allWordpressWpLocationsCategories.edges.map(
    ({ node }) => ({
      category: node.name,
      posts: node.locations,
    })
  )

  function handleClick(e) {
    setSelected(e.currentTarget.getAttribute("data-index"))
  }

  return (
    <>
      <Wrapper>

      <BackgroundImage fluid={sources} objectPosition="70% 50%" >
 
        <div className="content-container">
          <h2 className="heading">
            <GreenHeading text="Worldwide" />
            <br />
            <span className="secondary-heading">Ground Transportation</span>
          </h2>
          <p>{text}</p>
          <div className="locations">
            <Categories>
              {locations.map((item, index) => (
                <div
                  className={
                    index === parseInt(selected) ? "type selected" : "type"
                  }
                  key={index}
                  onClick={handleClick}
                  data-index={index}
                >
                  {item.category}
                </div>
              ))}
            </Categories>
            {isMobile ? (
              <Slider posts={locations[selected].posts}></Slider>
            ) : (
              <List posts={locations[selected].posts} />
            )}
          </div>
          <div className="button-wrapper">
            <ReserveNow />
          </div>
        </div>
        </BackgroundImage>
      </Wrapper>

      <Cars />
    </>
  )
}

export default Locations
