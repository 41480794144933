import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import BackgroundImage from 'gatsby-background-image'
import { sizes } from "../device"

const useImages = () => {
  const data = useStaticQuery(graphql`
    query {
      desktopBg: file(relativePath: { eq: "locations/bg-desktop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobileBg: file(relativePath: { eq: "locations/bg-desktop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      firstCar: file(relativePath: { eq: "locations/car1.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      secondCar: file(relativePath: { eq: "locations/car2.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      thirdCar: file(relativePath: { eq: "locations/car3.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  return data
}

const Bg = () => {
  const data = useImages()
  const sources = [
    data.mobileBg.childImageSharp.fluid,
    {
      ...data.desktopBg.childImageSharp.fluid,
      media: `(min-width: ${sizes.small}px)`,
    },
  ]
  return <Img fluid={sources} objectPosition="70% 50%" />
}

const FirstCar = () => {
  const data = useImages()
  return <Img fluid={data.firstCar.childImageSharp.fluid} />
}

const SecondCar = () => {
  const data = useImages()
  return <Img fluid={data.secondCar.childImageSharp.fluid} />
}

const ThirdCar = () => {
  const data = useImages()
  return <Img fluid={data.thirdCar.childImageSharp.fluid} />
}

export { Bg, FirstCar, SecondCar, ThirdCar }
