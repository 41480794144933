import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import arrow from "../../images/locations/next.svg"

const Wrapper = styled.div`
  width: 80vw;
  margin: 0 auto;
  .slick-arrow {
    z-index: 100;
    position: absolute;
    bottom: -10%;
    transform: translate(-50%, -50%);
    img {
      width: 25px;
      display: block;
    }
    &.slick-next {
      left: calc(50% + 30px);
    }
    &.slick-prev {
      left: calc(50% - 30px);
      transform: rotate(180deg) translate(50%, 50%);
    }
  }
`

const Slide = styled.div`
  .content {
    padding-bottom: 2rem;
    span {
      margin: 2rem 0;
      display: flex;
      align-items: center;
      font-size: 0.7rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      &::before {
        margin-right: 0.5rem;
        display: inline-block;
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        opacity: 0.5;
        border: 1px solid var(--white);
      }
    }
  }
`
const Arrow = props => {
  const { className, style, onClick } = props
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <img src={arrow} alt="Slider Button" />
    </div>
  )
}

const LocationsSlider = ({ posts }) => {
  let newPosts = [...posts]
  let newArray = []
  while (newPosts.length) newArray.push(newPosts.splice(0, 4))

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
  }

  return (
    <Wrapper>
      <Slider {...settings}>
        {newArray.map((item, i) => (
          <Slide className="slide-slide" key={i}>
            <div className="content">
              {item[0] && <span>{item[0].title}</span>}
              {item[1] && <span>{item[1].title}</span>}
              {item[2] && <span>{item[2].title}</span>}
              {item[3] && <span>{item[3].title}</span>}
            </div>
          </Slide>
        ))}
      </Slider>
    </Wrapper>
  )
}

export default LocationsSlider
