import React from "react"
import styled from "styled-components"
// import { useTrail, animated } from "react-spring"
// import "intersection-observer" // optional polyfill
// import { useInView } from "react-intersection-observer"
import device from "../device"
import { FirstCar, SecondCar, ThirdCar } from "./locations-image"

const Wrapper = styled.div`
  overflow: hidden;
  background: var(--black);
  width: 100%;
  display: flex;
  ${device.small`display: block`}
  .left {
    flex: 1;
    border-bottom: 3px solid var(--green);
    margin-bottom: 1.5rem;
    ${device.small`display: none;`}
  }
  .right {
    position: relative;
    margin-left: auto;
    text-align: right;
  }
  .car-image {
    display: inline-block;
    &:nth-child(1) {
      min-width: 120px;
      max-width: 330px;
      width: 20vw;
      margin-right: -2.5rem;
      ${device.small`position: absolute; right: 280px; top: 3rem`}
    }
    &:nth-child(2) {
      min-width: 180px;
      max-width: 350px;
      width: 22vw;
      margin-right: -3rem;
      ${device.small`position: absolute; right: 150px; bottom: 2rem;`}
    }
    &:nth-child(3) {
      min-width: 250px;
      max-width: 550px;
      width: 30vw;
      ${device.small`margin-right: -5rem;`}
    }
  }
`

const Cars = () => {
  // const [ref, inView] = useInView()
  // const trail = useTrail(3, {
  //   opacity: inView ? 1 : 0,
  //   transform: inView ? "translate3d(0,0,0)" : "translate3d(100px,0,0)",
  // })

  return (
    <Wrapper>
      <div className="left"></div>
      <div className="right">
        <span className="car-image">
          <FirstCar />
        </span>
        <span className="car-image">
          <SecondCar />
        </span>
        <span className="car-image">
          <ThirdCar />
        </span>
      </div>
    </Wrapper>
  )
}

export default Cars
